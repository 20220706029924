import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../../config/firebase-config"; // Firebase configuration import
import LiveStreamTableCell from "./LiveStreamTableCell";
import styles from "./livestream.module.css";

function LiveStreamTableContent() {
  const [liveStreamData, setLiveStreamData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLiveStreamData = async () => {
      try {
        const q = query(
          collection(db, "classifications"), // Confirm collection name
          orderBy("timestamp", "desc")
        );
        const snapshot = await getDocs(q);

        const data = snapshot.docs.map((doc) => {
          const item = doc.data();
          let id = "N/A"; // Default if no match found

          // Check if the filename matches the format with "_ID<number>_crop"
          const matchID = item.file_name.match(/_ID(\d+)_crop\.jpg$/);

          if (matchID) {
            id = matchID[1]; // Extract the ID number
          } else {
            // Handle case like "hornfly_110.jpg" or "mosquito_3.jpg"
            const matchSimple = item.file_name.match(/_(\d+)\.jpg$/);
            if (matchSimple) {
              id = matchSimple[1]; // Extract the trailing number
            }
          }

          // Capitalize the first letter of the classification
          const capitalize = (str) =>
            str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

          const classification = item.class
            ? capitalize(item.class)
            : "Unknown";

          // Convert Firestore Timestamp to JavaScript Date string
          const created = item.timestamp.toDate().toLocaleString();

          return {
            id,
            created,
            classification,
            device: "EcoTrack 1.0",
          };
        });

        setLiveStreamData(data);
      } catch (error) {
        console.error("Error fetching live stream data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLiveStreamData();
  }, []);

  if (loading) {
    return <p>Loading live stream data...</p>;
  }

  if (liveStreamData.length === 0) {
    return <p>No data available.</p>;
  }

  return (
    <div className={styles.table_layout}>
      <div className={styles.table_results_controls}>
        <p className={styles.showing_results}>
          Showing 1 - {liveStreamData.length} of {liveStreamData.length} results
        </p>
        <div className={styles.page_result_controls}>
          <p>Results per page:</p>
          <div className={styles.page_result_filter}>
            <p className={styles.filter_text}>10</p>
            <img
              className={styles.down_icon}
              src="/DownArrowIcon.png"
              alt="search"
            />
          </div>
        </div>
      </div>

      {/* Render Header Row */}
      <div className={styles.table_info_section}>
        <LiveStreamTableCell isHeader={true} />
      </div>

      <div className={styles.table_scroll}>
        {liveStreamData.map((dataPoint, index) => (
          <LiveStreamTableCell key={index} dataPoint={dataPoint} />
        ))}
        <div className={styles.buffer}></div>
      </div>
    </div>
  );
}

export default LiveStreamTableContent;
