import React from "react";
import styles from "./livestream.module.css";

function LiveStreamTableCell(props) {
  const defaultTitles = {
    id: "ID",
    created: "Created",
    classification: "Classification",
    device: "Device",
  };

  const dataPoint = props.dataPoint || defaultTitles;
  const isDefaultTitle = !props.dataPoint;

  const dataSectionClass = (isBold) =>
    `${styles.data_section} ${isBold ? styles.boldText : ""}`;

  return (
    
    <div className={styles.table_cell}>
      <div className={styles.checkbox_section}>
        <div className={styles.checkbox}></div>
      </div>
      <div className={`${styles.data_section} ${styles.id}`}>
      {dataPoint.id || "N/A"}
      </div>
      <div className={`${styles.data_section} ${styles.created}`}>
        {dataPoint.created || "No timestamp"}
      </div>
      <div className={`${styles.data_section} ${styles.classification}`}>
        {dataPoint.classification || "Unknown"}
      </div>
      <div className={`${styles.data_section} ${styles.device}`}>
        {dataPoint.device || "EcoTrack 1.0"}
      </div>
</div>
  );
}

export default LiveStreamTableCell;
