import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAWfxBUqR4wj8l7BqaCuLVwEynl2MnaRGY",
  authDomain: "ecotrack-win616.firebaseapp.com",
  databaseURL: "https://ecotrack-win616-default-rtdb.firebaseio.com",
  projectId: "ecotrack-win616",
  storageBucket: "ecotrack-win616.appspot.com",
  messagingSenderId: "168025477090",
  appId: "1:168025477090:web:9062d1832f5ddac0a333bb",
  measurementId: "G-YWTS14VLLM"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
