// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWfxBUqR4wj8l7BqaCuLVwEynl2MnaRGY",
  authDomain: "ecotrack-win616.firebaseapp.com",
  databaseURL: "https://ecotrack-win616-default-rtdb.firebaseio.com",
  projectId: "ecotrack-win616",
  storageBucket: "ecotrack-win616.appspot.com",
  messagingSenderId: "168025477090",
  appId: "1:168025477090:web:9062d1832f5ddac0a333bb",
  measurementId: "G-YWTS14VLLM"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };