import React, { useState } from "react";
import styles from "../settings.module.css";

export default function PaymentSettings() {
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Payment Information Submitted:", {
      cardName,
      cardNumber,
      expiryDate,
      cvv,
    });
  };

  return (
    <div className={styles.accountContainer}>
      <h1 className={styles.settingsTitle}>Payment Settings</h1>
      <form className={styles.tab_layout} onSubmit={handleSubmit}>
        <div className={styles.section}>
          <label className={styles.input_title}>Cardholder Name</label>
          <input
            type="text"
            className={styles.single_line_input}
            value={cardName}
            onChange={(e) => setCardName(e.target.value)}
            placeholder="Enter cardholder name"
            required
          />
        </div>

        <div className={styles.section}>
          <label className={styles.input_title}>Card Number</label>
          <input
            type="text"
            className={styles.single_line_input}
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="Enter card number"
            required
          />
        </div>

        <div className={styles.profile_section}>
          <div className={styles.profile_picture_section_left}>
            <label className={styles.input_title}>Expiry Date</label>
            <input
              type="text"
              className={styles.single_line_input}
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              placeholder="MM/YY"
              required
            />
          </div>

          <div className={styles.profile_picture_section_right}>
            <label className={styles.input_title}>CVV</label>
            <input
              type="password"
              className={styles.single_line_input}
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              placeholder="Enter CVV"
              required
            />
          </div>
        </div>

        <button type="submit" className={styles.update_profile_picture}>
          Save Payment Details
        </button>
      </form>
    </div>
  );
}
