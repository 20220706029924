import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard.module.css";

export default function DashboardRowOne(props) {
  const dashboard_data = props.dashboard_data;
  return (
    <>
      <div className={styles.key_insights}>
        <h2 className={styles.dashboard_title}>Key Insights</h2>
        <p className={styles.dashboard_subtitle}>
          A quick overview of the latest metrics.
        </p>
      </div>
      {/* <p className={styles.row_title}>{props.title}</p> */}
      <div className={styles.stats_row_one}>
        {dashboard_data.map((data, index) => (
          <div className={styles.top_stat}>
            <div className={styles.top_stat_top}>
              <div className={styles.top_stat_text}>
                <p className={styles.top_stat_text_title}>{data.number}</p>
                <p className={styles.top_stat_text_subtitle}>{data.title}</p>
              </div>
              <div className={styles.mini_bar_chart}>
                <div
                  style={{ height: "15px" }}
                  className={styles.top_stat_bar}
                ></div>

                <div
                  style={{ height: "25px" }}
                  className={styles.top_stat_bar}
                ></div>

                <div
                  style={{ height: "40px" }}
                  className={styles.top_stat_bar}
                ></div>

                <div
                  style={{ height: "30px" }}
                  className={styles.top_stat_bar}
                ></div>
              </div>
            </div>
            <div className={styles.top_stat_bottom}>
              <div className={styles.top_stat_line}></div>
              <div className={styles.top_stat_bottom_content}>
                <img
                  className={styles.clock_image}
                  src="/images/clock.png"
                  alt="last updated"
                ></img>
                <p className={styles.last_updated}>
                  Updated: {data.last_updated}
                </p>
              </div>
            </div>
          </div>
        ))}

        {/* <div className={styles.top_stat}></div>
      <div className={styles.top_stat}></div>
      <div className={styles.top_stat}></div> */}
      </div>
    </>
  );
}
