// ClassificationCard.js
import React from "react";
import styles from "./classifications.module.css";

function ClassificationsCard({ classification, onCardClick, edited }) {
  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Safely handle the date
  const getFormattedDate = (timestamp) => {
    if (!timestamp) return "N/A";
    if (typeof timestamp === "string") {
      // If timestamp is a string
      return new Date(timestamp).toLocaleString();
    } else if (timestamp.seconds) {
      // If timestamp is a Firebase Timestamp object
      return new Date(timestamp.seconds * 1000).toLocaleString();
    } else {
      return "N/A";
    }
  };

  // Extract username from email
  const getUsernameFromEmail = (email) => {
    if (!email) return "unknown";
    return email.split("@")[0];
  };

  return (
    <div
      className={`${styles.classifications_card} ${
        edited ? styles.edited_card : ""
      }`}
      onClick={() => onCardClick(classification)} // Call onCardClick when the card is clicked
    >
      <img
        className={styles.classifications_image}
        src={classification.image_url}
        alt={classification.class}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "fallback-image-url.png";
        }}
      />
      <div className={styles.classifications_info}>
        <div className={styles.classifications_subinfo}>
          <p className={styles.classification_title}>
            {capitalizeFirstLetter(
              classification.class || classification.classificationLabel
            )}
          </p>
          {classification.confidence !== undefined && (
            <p className={styles.classification_info_title}>
              Confidence Score: {(classification.confidence * 100).toFixed(2)}%
            </p>
          )}
          <p className={styles.classification_info_title}>
            Date: {getFormattedDate(classification.timestamp)}
          </p>
          {/* Display "Edited" text when the card has been edited */}
          {edited && (
            <p className={styles.edited_text}>Edited by {getUsernameFromEmail(classification.editedBy)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClassificationsCard;
