import React, { useContext, useState, useRef, useEffect } from "react";
import styles from "../settings.module.css";
import { AuthContext } from "../../../../context/AuthContext"; // Ensure path is correct

export default function AccountSettings() {
  const { profile, updateAccountProfilePicture } = useContext(AuthContext); // Access values from context
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Profile in AccountSettings:", profile); // Debugging log to see the profile object
    if (profile) {
      setLoading(false); // Stop loading once profile is available
    }
  }, [profile]); // Run whenever profile updates

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedProfilePicture(URL.createObjectURL(file));
      updateAccountProfilePicture(profile.uid, file); // Use profile.uid here
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (loading) {
    return <p>Loading profile...</p>;
  }

  if (!profile) {
    return <p>No profile data available. Please log in.</p>;
  }

  return (
    <div className={styles.main_layout}>
      <div className={styles.section}>
        <p className={styles.section_title}>Profile</p>
        <div className={styles.proflie_picture_section}>
          <div className={styles.profile_picture_bg}>
            <img
              alt="Profile"
              src={
                selectedProfilePicture ||
                profile.profilePicture || // Fetch profile picture from the profile object
                "/DefaultAccountImage.png"
              }
              className={styles.profile_picture}
            />
          </div>
          <div className={styles.update_profile_picture}>
            <p onClick={handleUploadButtonClick}>Upload Profile Picture</p>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleProfilePictureChange}
            />
          </div>
        </div>

        <div className={styles.profile_section}>
          <div className={styles.profile_picture_section_left}>
            <p className={styles.input_title}>First Name</p>
            <div className={styles.single_line_input}>
              {profile.firstName || "N/A"} {/* Display firstName */}
            </div>

            <p className={styles.input_title}>Country</p>
            <div className={styles.single_line_input}>
              {profile.country || "N/A"} {/* If country exists */}
            </div>

            <p className={styles.input_title}>Company Name</p>
            <div className={styles.single_line_input}>
              {profile.companyName || "N/A"}
            </div>
          </div>

          <div className={styles.profile_picture_section_right}>
            <p className={styles.input_title}>Last Name</p>
            <div className={styles.single_line_input}>
              {profile.lastName || "N/A"}
            </div>

            <p className={styles.input_title}>Phone Number</p>
            <div className={styles.single_line_input}>
              {profile.phoneNumber || "N/A"}
            </div>

            <p className={styles.input_title}>Company Website</p>
            <div className={styles.single_line_input}>
              {profile.companyWebsite || "N/A"}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <p className={styles.section_title}>Login Information</p>
        <div className={styles.profile_section}>
          <div className={styles.profile_picture_section_left}>
            <p className={styles.input_title}>Email Address</p>
            <div className={styles.single_line_input}>
              {profile.email}
            </div>
          </div>

          <div className={styles.profile_picture_section_right}>
            <p className={styles.input_title}>Password</p>
            <div className={styles.single_line_input}>********</div>
          </div>
        </div>
      </div>
    </div>
  );
}
