import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase"; // Import db separately
import { storage } from "../services/firebase"; // Import storage separately

import {
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification
} from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { sendPasswordResetEmail } from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setCurrentUser(user);
      fetchUserData(user.uid);
    }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        localStorage.setItem("authUser", JSON.stringify(user));
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
        setProfile(null);
        localStorage.removeItem("authUser");
      }
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email, password) => {
    try {
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setCurrentUser(credential.user);
      localStorage.setItem("authUser", JSON.stringify(credential.user));
      fetchUserData(credential.user.uid);
      try {
        return credential;
      } catch (err) {
        console.error("Error fetching data:", err);
        throw err;
      }
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userDocRef = doc(db, "accounts", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log(userData);
        setProfile(userData);
      } else {
        console.warn("No data found for user:", userId);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const createAccount = async (email, password, firstName, lastName) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;
      const uid = user.uid;

      const accountsRef = collection(db, "accounts");
      const userDocRef = doc(accountsRef, uid);

      await setDoc(userDocRef, {
        uid: uid,
        firstName: firstName,
        lastName: lastName,
        email: email,
      });
    
      // Send a verification email
      await sendEmailVerification(user);
      console.log("Verification email sent!");

      return userCredential;
    } catch (error) {
      console.error("Account creation error:", error);
      return null;
    }
  };

  const addWaitlistUser = async (email, name, number, company) => {
    const waitlistRef = collection(db, "waitlist");
    const q = query(waitlistRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      const newDocRef = doc(waitlistRef);
      await setDoc(newDocRef, {
        name: name,
        email: email,
        number: number,
        company: company,
      });

      const [firstName, lastName] = name.split(" ");
      // await addToSendGrid(email, firstName, lastName);
    }
  };

  const addUserToNewsletter = async (email) => {
    const waitlistRef = collection(db, "waitlist");

    const newDocRef = doc(waitlistRef);

    return setDoc(newDocRef, {
      email: email,
    });
  };

  const updateAccountProfilePicture = async (userId, file) => {
    try {
      const storageRef = ref(storage, `profile_pictures/${userId}`);

      await uploadBytes(storageRef, file);

      // Get the download URL directly
      const downloadURL = await getDownloadURL(storageRef);

      const userDocRef = doc(db, "accounts", userId);
      await updateDoc(userDocRef, {
        profilePicture: downloadURL,
      });

      setProfile((prevProfile) => ({
        ...prevProfile,
        profilePicture: downloadURL,
      }));

      console.log("Profile picture updated successfully.");
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
      setProfile(null);
      localStorage.removeItem("authUser");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        profile,
        createAccount,
        error,
        addWaitlistUser,
        addUserToNewsletter,
        currentUser,
        updateAccountProfilePicture,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent.");
  } catch (error) {
    console.error("Error sending password reset email:", error);
  }
};