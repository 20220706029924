import React from "react";
import { Line } from "react-chartjs-2";
import styles from "./dashboard.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const InsectLineGraph = ({ data }) => {
  const chartData = {
    labels: data.map((entry) => entry.date), // Dates on the x-axis
    datasets: [
      {
        label: "Insects Detected",
        data: data.map((entry) => entry.count), // Detection counts on the y-axis
        borderColor: "rgba(220, 53, 69, 1)", // Vibrant red for the line
        backgroundColor: "rgba(220, 53, 69, 0.1)", // Light red fill for transparency
        pointBackgroundColor: "rgba(255, 255, 255, 1)", // White points
        pointBorderColor: "rgba(220, 53, 69, 1)", // Red border for points
        pointHoverBackgroundColor: "rgba(220, 53, 69, 0.8)", // Hover effect
        pointHoverBorderColor: "rgba(255, 255, 255, 1)", // White hover border
        tension: 0.4, // Smooth line curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black", // Legend text color
          font: {
            family: "Poppins", // Use Poppins font
            size: 14, // Optional: Adjust font size
          },
        },
      },
      
    },
    scales: {
      x: {
        ticks: {
          color: "black", // X-axis tick color
          font: {
            family: "Poppins", // Use Poppins font
          },
        },
      },
      y: {
        ticks: {
          color: "black", // Y-axis tick color
          font: {
            family: "Poppins", // Use Poppins font
          },
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default InsectLineGraph;