import React from "react";
import { Sidebar } from "../../layout/Sidebar/Sidebar";
import TestClassificationComponent from "../../components/portal_components/TestClassificationComponent/TestClassificationComponent";

const TestClassificationPage = () => {
  return (
    <>
      <Sidebar
        childComponent={<TestClassificationComponent />}
        activeText="Test Classification"
      />
    </>
  );
};

export default TestClassificationPage;
