import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotificationsSidebar from "../Notifications/Notifications";
import { AuthContext } from "../../context/AuthContext";
import styles from "./navbar.module.css";

export default function Navbar(props) {
  const { logout, currentUser } = useContext(AuthContext);

  const [showMenu, setShowMenu] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(true);
  const menuRef = useRef();
  const notificationsRef = useRef();
  const navigate = useNavigate();

  const getUsernameFromEmail = (email) => {
    if (!email) return "User";
    return email.split("@")[0];
  };
  

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/sign-in");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleSettings = () => navigate("/settings");

  const toggleMenu = () => setShowMenu(!showMenu);

  const markAllAsOpened = () => {
    setHasNewNotifications(false);
  };

  const handleNotificationsClick = () => {
    if (!notificationsOpen && hasNewNotifications) {
      markAllAsOpened();
    }
    setNotificationsOpen(!notificationsOpen);
  };

  const onViewNotifications = () => {
    setHasNewNotifications(false);
  };

  const handleClickOutside = (event) => {
    if (
      notificationsRef.current &&
      !notificationsRef.current.contains(event.target)
    ) {
      setNotificationsOpen(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleCloseNotifications = () => {
    setNotificationsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () =>
      document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={styles.portal_navbar}>
      <div className={styles.portal_navbar_sidebar_extension}></div>
      <div className={styles.portal_navbar_content}>
        <div className={styles.portal_navbar_left_content}></div>
        <div className={styles.portal_navbar_right_content}>
          {/* Username Button */}
          {currentUser && (
            <div
              className={styles.username_button}
              onClick={toggleMenu}
              ref={menuRef}
            >
              <span className={styles.username_text}>
                {getUsernameFromEmail(currentUser.email)}
              </span>
              <img
                src="/images/NavbarIcons/DownArrowIcon.png"
                alt="Dropdown Arrow"
                className={styles.dropdown_arrow}
              />
            </div>
          )}

          {/* Notifications Icon with Red Dot */}
          <div
            className={styles.navbar_item}
            onClick={handleNotificationsClick}
          >
            <img
              src="/images/NavbarIcons/NotificationsIcon.png"
              alt="Notifications"
              className={styles.navbar_icon}
            />
            {hasNewNotifications && (
              <div className={styles.notification_dot}></div>
            )}
          </div>

          {notificationsOpen && (
            <div
              className={styles.notifications_sidebar}
              ref={notificationsRef}
            >
              <NotificationsSidebar
                onViewNotifications={onViewNotifications}
                onClose={handleCloseNotifications}
              />
            </div>
          )}

          {/* Dropdown Menu */}
          {showMenu && (
            <div className={styles.profile_menu} ref={menuRef}>
              <div className={styles.menu_item} onClick={handleSettings}>
                <p className={styles.menu_item_text}>Settings</p>
              </div>
              <div className={styles.menu_item} onClick={handleLogout}>
                <p className={styles.menu_item_text}>Logout</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}