import React, { useState, useContext } from "react";
import styles from "./auth.module.css";
import { AuthContext } from "../../context/AuthContext";

const SignUpComponent = () => {
  const { createAccount } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [error, setError] = useState(null);

  const PROJECT_KEY = "ecotrack-pilot"; // Hardcoded valid key

  async function handleSubmit() {
    if (projectKey !== PROJECT_KEY) {
      setError("Invalid Project Key. Please enter the correct key.");
      return;
    }

    try {
      await createAccount(email, password, firstName, lastName);
      setError("Account created successfully. Please verify your email before logging in.");
    } catch (error) {
      setError("Failed to create an account. Please try again.");
      console.error("Sign-up error:", error);
    }
  }

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          <img alt="logo" src="/Favicon.png" className={styles.logo_icon} />
          <p className={styles.page_title}>Sign Up</p>

          <p className={styles.login_input_titles}>First Name</p>
          <div className={styles.email_input}>
            <input
              onChange={(event) => setFirstName(event.target.value)}
              className={styles.input}
              placeholder="First Name"
            />
          </div>

          <p className={styles.login_input_titles}>Last Name</p>
          <div className={styles.email_input}>
            <input
              onChange={(event) => setLastName(event.target.value)}
              className={styles.input}
              placeholder="Last Name"
            />
          </div>

          <p className={styles.login_input_titles}>Email Address</p>
          <div className={styles.email_input}>
    
            <input
              type="email"
              onChange={(event) => setEmail(event.target.value)}
              className={styles.input}
              placeholder="Email"
            />
          </div>

          <p className={styles.login_input_titles}>Password</p>
          <div className={styles.email_input}>
            
            <input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              className={styles.input}
              placeholder="Password"
            />
          </div>

          <p className={styles.login_input_titles}>Project Key</p>
          <div className={styles.email_input}>
            <img className={styles.auth_input_icon} src="/KeyIcon.png" alt="project-key" />
            <input
              type="text"
              onChange={(event) => setProjectKey(event.target.value)}
              className={styles.input}
              placeholder="Enter Project Key"
            />
          </div>

          {error && <p className={styles.error_text}>{error}</p>}

          <div onClick={handleSubmit} className={styles.page_button}>
            <p className={styles.join_button_text}>Sign Up</p>
          </div>
        </div>
      </div>

      <img className={styles.landing_image} src="/NyteLyfeHomeAbstractBackground.png" alt="landing" />
    </div>
  );
};

export default SignUpComponent;
