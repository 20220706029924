import React, { useState, useEffect, useContext } from "react";
import styles from "./classifications.module.css";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../config/firebase-config"; // Adjust the path as needed

import SearchBarHeader from "../UniversalComponents/search_bar_header";
import ClassificationsCard from "./ClassificationCard";
import { AuthContext } from "../../../context/AuthContext"; // Import AuthContext

function ClassificationsComponent() {
  const [classifications, setClassifications] = useState([]);
  const [sortOption, setSortOption] = useState("newest");
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [newClass, setNewClass] = useState(""); // Track the new classification input
  const [isEditing, setIsEditing] = useState(false); // Controls the visibility of the input field
  const [editedClassificationId, setEditedClassificationId] = useState(null); // Track the edited classification ID

  const { currentUser } = useContext(AuthContext); // Get currentUser from AuthContext

  useEffect(() => {
    const classificationsRef = collection(db, "classifications");
    const q =
      sortOption === "alphabetical"
        ? query(classificationsRef, orderBy("class"))
        : query(classificationsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const classificationsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClassifications(classificationsData);
    });

    return () => unsubscribe();
  }, [sortOption]);

  const handleSortChange = (e) => setSortOption(e.target.value);

  const handleCardClick = (classification) => {
    setSelectedClassification(classification);
    setIsEditing(false); // Reset editing state when a new card is selected
    setNewClass(""); // Reset new class input
  };

  const closePopup = () => {
    setSelectedClassification(null);
    setIsEditing(false); // Reset editing state
    setNewClass(""); // Reset new class input
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing); // Toggle the input field visibility
  };

  const handleChangeClassification = async () => {
    if (!newClass.trim()) {
      alert("Please enter a valid class.");
      return;
    }
    try {
      const classificationDoc = doc(
        db,
        "classifications",
        selectedClassification.id
      );
  
      // Get the current user's email from AuthContext
      const userEmail = currentUser ? currentUser.email : "unknown";
  
      // Update Firestore with new class, editedBy, and isEdited fields
      await updateDoc(classificationDoc, {
        class: newClass,
        editedBy: userEmail,
        isEdited: true, // Add this field to persist the edited state
        editedAt: new Date(), // Optional: Store when the edit occurred
      });
  
      alert("Classification updated successfully!");
  
      closePopup(); // Close the modal after updating
    } catch (error) {
      console.error("Error updating classification:", error);
      alert("Failed to update classification.");
    }
  };
  
  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Classifications</p>
      <SearchBarHeader />

      <div>
        <label
          htmlFor="sort"
          style={{
            marginRight: "5px",
            fontWeight: "500",
            fontSize: "16px",
            fontFamily: "Poppins",
          }}
        >
          Sort by:
        </label>
        <select
          id="sort"
          value={sortOption}
          onChange={handleSortChange}
          className={styles.sort_dropdown}
        >
          <option value="newest" className={styles.sort_text}>
            Newest
          </option>
          <option value="alphabetical" className={styles.sort_text}>
            Alphabetical
          </option>
        </select>
      </div>

      <div className={styles.table_scroll}>
        {classifications.map((classification) => (
          <ClassificationsCard
            key={classification.id}
            classification={classification}
            onCardClick={handleCardClick}
            // Highlight the card if it's recently edited
            edited={classification.isEdited}
          />
        ))}
        <div className={styles.buffer}></div>
      </div>

      {selectedClassification && (
        <div className={styles.popup_overlay} onClick={closePopup}>
          <div
            className={styles.popup_content}
            onClick={(e) => e.stopPropagation()}
          >
            <button className={styles.close_button} onClick={closePopup}>
              &times;
            </button>
            <img
              src={selectedClassification.image_url}
              alt={selectedClassification.class}
              className={styles.popup_image}
            />
            <p>
              <strong>Class:</strong>{" "}
              {selectedClassification.class.charAt(0).toUpperCase() +
                selectedClassification.class.slice(1)}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {selectedClassification.timestamp?.seconds
                ? new Date(
                    selectedClassification.timestamp.seconds * 1000
                  ).toLocaleString()
                : "N/A"}
            </p>

            {/* Toggle button and input field */}
            {!isEditing ? (
              <button
                className={styles.change_class_button}
                onClick={toggleEdit}
              >
                Change Classification
              </button>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Enter new class"
                  value={newClass}
                  onChange={(e) => setNewClass(e.target.value)}
                  className={styles.input_field}
                />
                <div className={styles.button_group}>
                  <button
                    className={styles.change_class_button}
                    onClick={handleChangeClassification}
                  >
                    Submit
                  </button>
                  <button className={styles.cancel_button} onClick={toggleEdit}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ClassificationsComponent;
