import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import DashboardComponent from "../../components/portal_components/DashboardComponents/DashboardComponent";

const DashboardPage = () => {
  return (
    <>
      <Sidebar childComponent={<DashboardComponent />} 
      activeText="Dashboard" />
    </>
  );
};

export default DashboardPage;
