// src/data/dashboard_data.js
import { db } from "../services/firebase"; // Adjust the path if needed
import { collection, query, getDocs, orderBy } from "firebase/firestore";

// Helper function to get a human-readable time difference
const getTimeDifferenceString = (timestamp) => {
  const now = new Date();
  const diffInSeconds = Math.floor((now - timestamp) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes}min ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours}h ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days}d ago`;
  }
};

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Fetch and format dashboard data from Firestore
export const fetchDashboardData = async () => {
  try {
    const q = query(
      collection(db, "classifications"),
      orderBy("timestamp", "asc")
    );
    const querySnapshot = await getDocs(q);

    let totalClassifications = 0;
    let classificationsPastWeek = 0;
    const detectionsByDate = {};
    const classCounts = {};

    let lastClassificationTimestamp = null; // For total insects classified
    let lastClassificationPastWeekTimestamp = null; // For insects classified in the past week
    let currentMostCommonClass = null;
    let currentMostCommonClassCount = 0;
    let currentMostCommonClassLastChanged = null;

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const timestamp = data.timestamp.toDate();
      const date = timestamp.toISOString().split("T")[0];

      totalClassifications++;
      detectionsByDate[date] = (detectionsByDate[date] || 0) + 1;

      // Update classCounts
      const classificationClass = data.class;
      if (classificationClass) {
        const className = capitalizeFirstLetter(classificationClass);
        classCounts[className] = (classCounts[className] || 0) + 1;

        // Check if this class now has the highest count
        const classCount = classCounts[className];
        if (classCount > currentMostCommonClassCount) {
          currentMostCommonClass = className;
          currentMostCommonClassCount = classCount;
          currentMostCommonClassLastChanged = timestamp;
        }
      }

      // Check if the classification is within the past week
      if (timestamp >= oneWeekAgo) {
        classificationsPastWeek++;
        lastClassificationPastWeekTimestamp = timestamp;
      }

      // Update lastClassificationTimestamp
      lastClassificationTimestamp = timestamp;
    });

    // Prepare last_updated fields
    const totalClassificationsLastUpdated = lastClassificationTimestamp
      ? getTimeDifferenceString(lastClassificationTimestamp)
      : "No data";

    const classificationsPastWeekLastUpdated =
      lastClassificationPastWeekTimestamp
        ? getTimeDifferenceString(lastClassificationPastWeekTimestamp)
        : "No data";

    const mostCommonClassLastUpdated = currentMostCommonClassLastChanged
      ? getTimeDifferenceString(currentMostCommonClassLastChanged)
      : "No data";

    // Prepare recent days data for the first stat (you can adjust as needed)
    const recentDaysData = Object.values(detectionsByDate).slice(-4);

    // Return formatted data for the dashboard, including classCounts
    return {
      campaigns_overview_stats: [
        {
          id: "1",
          number: totalClassifications,
          title: "Insects Classified",
          recent_days_data: recentDaysData,
          last_updated: totalClassificationsLastUpdated,
        },
        {
          id: "2",
          number: classificationsPastWeek,
          title: "Insects Classified This Week",
          recent_days_data: [], // You can add relevant data if needed
          last_updated: classificationsPastWeekLastUpdated,
        },
        {
          id: "3",
          number: currentMostCommonClass || "N/A",
          title: "Most Common Class",
          recent_days_data: [], // Placeholder data
          last_updated: mostCommonClassLastUpdated,
        },
      ],
      lineGraphData: Object.entries(detectionsByDate).map(
        ([date, count]) => ({
          date,
          count,
        })
      ),
      classCounts, // Include classCounts in the returned data
    };
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    return { campaigns_overview_stats: [], lineGraphData: [], classCounts: {} };
  }
};
