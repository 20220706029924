import React, { useState } from "react";
import styles from "./testclassification.module.css"; // Import CSS module

const TestClassificationComponent = () => {
  const [resultClass, setResultClass] = useState("");
  const [resultConfidence, setResultConfidence] = useState("");
  const [showResult, setShowResult] = useState(false);

  const submitForm = (event) => {
    event.preventDefault();
    const fileInput = document.getElementById("fileInput");
    const formData = new FormData();
    formData.append("file", fileInput.files[0]);

    const url =
      "https://fastapi-classification-service-168025477090.us-central1.run.app/upload-image";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setResultClass(data.class);
        setResultConfidence(data.confidence.toFixed(2));
        setShowResult(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className={styles.body}>
      <h1 className={styles.title}>Insect Image Classification</h1>
      <div className={styles.uploadForm}>
        <form id="uploadForm" onSubmit={submitForm}>
          <input type="file" id="fileInput" />
          <button type="submit" className={styles.button}>Predict</button>
        </form>
      </div>
      {showResult && (
        <div className={styles.resultContainer}>
          <h2>Classification Result</h2>
          <p>
            <strong>Class: </strong>
            {resultClass}
          </p>
          <p>
            <strong>Confidence: </strong>
            {resultConfidence}
          </p>
        </div>
      )}
    </div>
  );
};

export default TestClassificationComponent;
