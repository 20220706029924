import React, { useEffect, useState, useRef } from "react";
import styles from "./notifications.module.css";
import { collection, query, orderBy, limit, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../../services/firebase";

const LineDivider = () => <div className={styles.lineDivider}></div>;

const timeAgo = (timestamp) => {
  const now = new Date();
  const secondsAgo = Math.floor((now - timestamp) / 1000);

  const minutes = Math.floor(secondsAgo / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  return `${secondsAgo} second${secondsAgo > 1 ? "s" : ""} ago`;
};

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

function NotificationsSidebar({ onClose, onViewNotifications, onNotificationsRead }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const sidebarRef = useRef(null);

  // Fetch notifications from Firestore
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const q = query(
          collection(db, "classifications"),
          orderBy("timestamp", "desc"),
          limit(5)
        );
        const snapshot = await getDocs(q);

        const data = snapshot.docs.map((doc) => {
          const item = doc.data();
          return {
            id: doc.id,
            text: `${capitalizeFirstLetter(item.class)} detected`,
            imageUrl: item.image_url,
            timestamp: item.timestamp.toDate(),
            isOpened: item.isOpened || false,
          };
        });

        setNotifications(data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  // Mark all notifications as opened and update Firestore
  const markAllAsOpened = async () => {
    try {
      const updatedNotifications = notifications.map((notif) => ({
        ...notif,
        isOpened: true,
      }));

      await Promise.all(
        updatedNotifications.map((notif) =>
          updateDoc(doc(db, "classifications", notif.id), { isOpened: true })
        )
      );

      setNotifications(updatedNotifications);
      onViewNotifications(); // Update the navbar state
      onNotificationsRead(); // Notify the navbar that notifications were read
    } catch (error) {
      console.error("Error marking notifications as opened:", error);
    }
  };

  // Close the sidebar and mark notifications as read
  const handleClose = async () => {
    onClose(); // Close the sidebar
    await markAllAsOpened(); // Mark all notifications as read after closing
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        handleClose(); // Close the sidebar when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return <p>Loading notifications...</p>;
  }

  return (
    <div className={styles.notifications_sidebar} ref={sidebarRef}>
      <div className={styles.notifications_header}>
        <p className={styles.notifications_sidebar_text}>Notifications</p>
      </div>
      <div className={styles.notifications_sidebar_content}>
        {notifications.map((notification, index) => (
          <div key={index} className={styles.notification_cell}>
            <img
              src={notification.imageUrl || "/DefaultNotificationImage.png"}
              className={styles.notif_image}
              alt="notification"
            />
            {!notification.isOpened && (
              <div className={styles.unread_dot} /> // Red dot for unread notifications
            )}

            <div className={styles.notification_cell_text}>
              <div className={styles.notification_cell_top}>
                <p className={styles.notification_text}>{notification.text}</p>
              </div>
              <div className={styles.notification_cell_bottom}>
                <p className={styles.notification_time}>
                  {timeAgo(notification.timestamp)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NotificationsSidebar;
