// src/components/BarGraph.js
import React from "react";
import { Bar } from "react-chartjs-2";
import styles from "./dashboard.module.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const InsectBarGraph = ({ classCounts = {} }) => {
  // Ensure classCounts is an object
  const safeClassCounts = classCounts || {};

  // Add a console log to verify classCounts
  console.log("InsectBarGraph classCounts:", safeClassCounts);

  const labels = Object.keys(safeClassCounts);
  const dataValues = Object.values(safeClassCounts);

  // Handle the case when there are no labels/data
  if (labels.length === 0) {
    return <p>No data available to display.</p>;
  }

  const chartData = {
    labels: labels, // Class names on the x-axis
    datasets: [
      {
        label: "Insect Class Frequency",
        data: dataValues, // Frequency counts on the y-axis
        backgroundColor: "rgba(220, 53, 69, 1)", // Vibrant red for the bars
        borderColor: "rgba(220, 53, 69, 4)", // Darker red border
        borderWidth: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black", // Legend text color
          font: {
            family: "Poppins", // Use Poppins font
            size: 14, // Adjust font size as needed
          },
        },
      },
      
    },
    scales: {
      x: {
        ticks: {
          color: "black", // X-axis tick color
          font: {
            family: "Poppins", // Use Poppins font
          },
        },
        grid: {
          display: false, // Hide grid lines on x-axis
        },
      },
      y: {
        ticks: {
          color: "black", // Y-axis tick color
          font: {
            family: "Poppins", // Use Poppins font
          },
          beginAtZero: true, // Start y-axis at zero
          precision: 0, // Show whole numbers only
        },
        grid: {
          color: "rgba(200, 200, 200, 0.2)", // Light gray grid lines
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default InsectBarGraph;
