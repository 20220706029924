import React, { useState, useEffect } from "react";
import styles from "./dashboard.module.css";
import DashboardRowOne from "./DashboardRow1"; // Keep row one at the top
import InsectLineGraph from "./LineGraph";
import InsectBarGraph from "./BarGraph"; // Import the graph
import { fetchDashboardData } from "../../../data/dashboard_data"; // Import the fetch function

export default function DashboardComponent() {
  // Initialize state to hold dashboard data
  const [dashboardData, setDashboardData] = useState({
    campaigns_overview_stats: [],
    lineGraphData: [],
  });

  // Fetch dashboard data on component mount
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDashboardData(); // Fetch data from Firestore
      setDashboardData(data); // Set the fetched data to state
    };

    fetchData(); // Call the function
  }, []);

  return (
    <div className={styles.main_layout}>
      {/* Render DashboardRowOne with fetched data */}
      <DashboardRowOne
        dashboard_data={dashboardData.campaigns_overview_stats}
        title="Dashboard"
      />

      {/* Render the graph with lineGraphData */}
      <div className={styles.graph_container}>
          <h2 className={styles.dashboard_title}>Insect Detections Over Time</h2>
          <p className={styles.dashboard_subtitle}>
            
          </p>
        
        <InsectLineGraph data={dashboardData.lineGraphData} />
      </div>

      <div className={styles.graph_container}>
        <h2 className={styles.dashboard_title}>Classification Frequency</h2>
        {/* Pass classCounts prop correctly */}
        <InsectBarGraph classCounts={dashboardData.classCounts} />
      </div>


    </div>
  );
}
